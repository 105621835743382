<script setup>
// imports
import { useNotificationStore } from "@/javascript/stores/notification-store";
import { storeToRefs } from "pinia";
const notificationStore = useNotificationStore();

// vars
const {
  snackbarVisible,
  snackbarMessage,
  snackbarButtonLink,
  snackbarButtonText,
  snackbarDismissible
} = storeToRefs(notificationStore);

// methods
const snackbarClick = () => {
  window.open(snackbarButtonLink.value,'_blank');
};
</script>

<template>
  <div
    class="fmc-snackbar-wrapper"
  >
    <div
      class="fmc-snackbar"
      :hidden="!snackbarVisible"
    >
      <div class="fmc-snackbar__content">
        {{ snackbarMessage }}
      </div>
      <a
        v-if="snackbarButtonText && snackbarButtonLink"
        class="fmc-snackbar__cta"
        @click="snackbarClick"
      >{{ snackbarButtonText }}</a>
      <button
        v-if="snackbarDismissible"
        class="fmc-snackbar__dismiss fds-icon fds-font--ford-icons__clear"
        aria-label="Dismiss"
        @click="notificationStore.hideNotification()"
      />
    </div>
  </div>
</template>


<style lang="scss" scoped>
.fmc-snackbar-wrapper {
    position: fixed;
    display: flex;
    left: 0;
    width: 100%;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    bottom: 60px;
    z-index: 999;
    .fmc-snackbar__cta, .fmc-snackbar__dismiss {
    cursor: pointer;
    }
}
</style>
