export const en = {
  assessments: {
    app_name: "Quick Lane 45-Day Training Assessment",
    overview_title: "Overview",
    overview_text_1: "Use the search function below to pull up a Quick Lane store, Learner or Course and view the current the 45-Day Post-Training Assessment Survey and/or completion status. Once a learner has reached the 45-day post-training milestone for a Quick Lane training course, the Field Operations Specialist (FOS) should make arrangements to visit the store in the near future to complete the 45-DayPost-Training Assessment Survey on the learner's behalf.",
    overview_text_2: "You may also edit the learner's employment status or title here as needed.",
    overview_text_3: "Completion of the Post-Training Assessment for each course/learner is mandatory in order to help Quick Lane measure knowledge transfer and make continuous improvements in curriculum, training delivery and facilitation.",
  },
  reporting: {
    reporting_text: "Here, you can download a report of your Quick Lane stores' 45-Day Post-Training Assessment status. Be sure to apply filters to scope down results.",
    market_area_text: "Market Area",
    select_market_text: "Select a Market",
    region_text: "Metro Region",
    select_region_text: "Select a Region",
    store_text: "Store(s)",
    select_store_text: "Select Store(s)",
    download_report_text: "Download Report",
    generating_report_text: "Generating Report"
  },
  components: {
    search_bar: {
      search_label: "Search for Quick Lane Store, Learner or Course",
    }
  }
};
