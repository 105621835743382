<script>
import Snackbar from '@/javascript/components/snackbar.vue';

export default {
  name: 'App',
  components: { Snackbar },
};
</script>

<template>
  <div id="vue-app">
    <router-view />
    <Snackbar />
  </div>
</template>

<style lang="scss" scoped>
</style>
