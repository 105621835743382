// Setup header
const profileMenuLink = document.getElementById('profile-menu-link');
const profileDropdown = document.getElementById('profile-dropdown');

const menuContainer = document.getElementById('menu-container');
const mobileIcon = document.getElementById('mobile-icon');
const menuText = document.getElementById('mobile-menu-text');

if (profileMenuLink && profileDropdown) {
  profileMenuLink.addEventListener('click', () => {
    if (menuContainer.classList.contains('menu-opened')) {
      mobileIcon.src = '/vite-dev/assets/icons/mobile-menu.png';
      menuText.style.display = 'block';
      mobileIcon.style.marginBottom = '0';
    } else {
      mobileIcon.src = '/vite-dev/assets/icons/close-icon.png';
      menuText.style.display = 'none';
      mobileIcon.style.marginBottom = '7px';
    }

    // Toggle the 'menu-opened' class to track the state
    menuContainer.classList.toggle('menu-opened');
    profileDropdown.classList.toggle('show');
  });
}

// Vue Imports
import { createApp } from 'vue';

// Pinia
import { createPinia } from 'pinia';

// Ford Design System
import '@ford/ford-design-system/dist/web-components.js';

// Honeybadger for Error Reporting
// import HoneybadgerVue from '@honeybadger-io/vue';

// Vue-Select
import vSelect from 'vue-select';

// PrimeVue
import PrimeVue from 'primevue/config';

//// Font Awesome - removed for now
//// for the package.json
// "@fortawesome/fontawesome-svg-core": "^6.5.1",
// "@fortawesome/pro-duotone-svg-icons": "^6.5.1",
// "@fortawesome/pro-light-svg-icons": "^6.5.1",
// "@fortawesome/pro-regular-svg-icons": "^6.5.1",
// "@fortawesome/pro-solid-svg-icons": "^6.5.1",
// "@fortawesome/pro-thin-svg-icons": "^6.5.1",
// "@fortawesome/vue-fontawesome": "^3.0.5",
//// imports
// import { library } from '@fortawesome/fontawesome-svg-core';
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// import {
//   faGrid as fasGrid,
//   faList as fasList,
// } from '@fortawesome/pro-solid-svg-icons';
//// add icons to library
// library.add(fasGrid);
// library.add(fasList);
//// chain on app setup
// .component('font-awesome-icon', FontAwesomeIcon);

import { datadogRum } from '@datadog/browser-rum';
// eslint-disable-next-line no-undef
const railsEnvironment = process.env.RAILS_ENV;
// eslint-disable-next-line no-undef
const ddEnvironment = process.env.DD_VERSION;

// DataDog configuration
if (railsEnvironment !== 'development') {
  datadogRum.init({
    // These get exposed on frontend anyway, so there's no point in trying to obfuscate the id
    applicationId: 'b1f5be21-5898-4887-975f-c711968ba3ce',
    clientToken: 'pub405cdfe6e8f880ad898b8c6f49ab02dd',
    site: 'datadoghq.com',
    service: 'quicklane-training-assessment',
    env: railsEnvironment,
    // Specify a version number to identify the deployed version of your application in Datadog
    // testing for gitlab to see if it deploys version
    version: ddEnvironment,
    sessionSampleRate: 90,
    sessionReplaySampleRate: 10,
    trackUserInteractions: railsEnvironment === 'production',
    trackResources: railsEnvironment === 'production',
    trackLongTasks: railsEnvironment === 'production',
    defaultPrivacyLevel: 'mask-user-input',
  });
}


// App
import App from "@/javascript/views/App.vue";

// Router
import router from '@/javascript/router/router';

// I18n
import { createI18n } from 'vue-i18n';
import { en as usaEnglish } from '@/javascript/i18n/usa_english';
// import { cen as canEnglish } from '@/javascript/i18n/canadian_english';
// import { fr as canFrench } from '@/javascript/i18n/canadian_french';

// App-made Components
import BaseSpinner from '@/javascript/components/base-spinner.vue';

// Mount App
const mountApp = (locale) => {
  const app = createApp(App);
  app.config.devtools = true;
  const pinia = createPinia();
  const i18n = createI18n({
    legacy: false,
    locale: locale,
    fallbackLocale: 'en',
    messages: {
      en: usaEnglish,
      // cen: canEnglish,
      // fr: canFrench,
    },
  });

  app
    .use(pinia)
    .use(i18n)
    .use(router)
    .use(PrimeVue)
    .component('base-spinner', BaseSpinner)
    .component('v-select', vSelect);

  app.mount('#app');
};
mountApp('en');
