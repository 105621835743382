// imports
import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useNotificationStore = defineStore('notificationStore', () => {
  // vars
  const snackbarVisible = ref(false);
  const snackbarMessage = ref('');
  const snackbarButtonText = ref('');
  const snackbarButtonLink = ref('');
  const snackbarDismissible = ref(false);
  const snackbarTimeout = ref(0);
  const notification_id = ref(Date.now());
  const delay = t => new Promise(resolve => setTimeout(resolve, t));

  // methods
  const showNotification = async (
    message = '',
    timeout = 0,
    dismissible = false,
    btn_text = null,
    btn_link = null
  ) => {
    let current_id = Date.now();
    notification_id.value = current_id;
    snackbarVisible.value = true;
    snackbarMessage.value = message;
    snackbarButtonText.value = btn_text;
    snackbarButtonLink.value = btn_link;
    snackbarTimeout.value = timeout;
    snackbarDismissible.value = dismissible;

    if (snackbarTimeout.value > 0) {
      await delay(snackbarTimeout.value*1000);
      if(current_id === notification_id.value) {
        hideNotification();
        await delay(300);
      } else {
        console.log('skipped');
      }

    }
  };
  const hideNotification = () => {
    console.log('hide notification');

    snackbarVisible.value = false;
  };

  // exports
  return {
    snackbarVisible,
    snackbarMessage,
    snackbarButtonText,
    snackbarButtonLink,
    snackbarDismissible,
    showNotification,
    hideNotification
  };
});

